<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId == 'newTable'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      style="display: block"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal_ac_head single-column-popup"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalCenterTitle">
              Add New Table
            </h5>
            <a
              class="close"
              @click="$store.dispatch('modalClose', 'newTable')"
            >
              <span aria-hidden="true">×</span>
            </a>
          </div>
          <form
            @submit.prevent="submit"
            method="post"
            class="bg-theam-secondary"
            enctype="multipart/form-data"
          >
            <div class="modal-body row">
              <div class="col-md-12 pd-r-7-5">
                <div class="group">
                  <label class="group-label">Table Detail</label>
                  <div class="group-attribute-container">
                    <div class="row mb-2">
                      <div class="col-md-5 mr-0 pr-0">Title *</div>
                      <div class="col-md-7 ml-0 pl-0">
                        <input
                          type="input"
                          name=""
                          id="bank"
                          class="form-control"
                          v-model="formData.table"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-right mt-3">
                <button type="submit" class="btn btn-sm btn-primary">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      formData: {
        table: "",
      },
    };
  },
  computed: {
    ...mapGetters([
      "processing",
      "eventMessage",
      "dataLists",
      "modalId",
      "dataId",
      "modalState",
      "modalMode",
    ]),
  },
  mounted() {
    //   this.$store.commit('getData','api/items');
  },
  methods: {
    submit() {
      if (this.modalMode == "create") {
        this.$store.commit("setApiUrl", "api/table/store");
        this.$store.commit("addData", this.formData);
      } else if (this.modalMode == "edit") {
        this.$store.commit(
          "setApiUrl",
          `api/table/update/${this.formData.id}`
        );
        this.$store.commit("updateData", this.formData);
      }
    },
    clearData() {
      this.formData.id = "";
      this.formData.table = "";
    },
  },
  watch: {
    dataId(value) {
      if (this.modalId == 'newTable') {
        this.formData = this.dataLists.find(function (data) {
          return data.id == value;
        });
      }
    },
  },
  destroyed() {
    this.$store.dispatch("destroyEvent");
  },
};
</script>