<template>
  <div class="content-body">
    <div class="row mt-2 mg-sm-0 no-print">
      <div class="col-6">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">Table</a>
                </li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Manage Table
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Manage Table</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-6 tx-right">
        <button
          type="button"
          class="btn btn-primary mg-t-8 mg-r-0 mg-b-0"
          @click="
            $store.dispatch('modalWithMode', { id: 'newTable', mode: 'create' })
          "
        >
          New Table
        </button>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head pt-0">
      <div class="row">
        <div class="filter-input col-9 d-block mr-0 pr-0">
          <select v-model="filter.size" class="mr-2 mg-t-5">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
          </select>
        </div>
        <div class="col-3 ml-0 pl-0 mg-t-5">
          <input type="text" v-model="filter.key"  class="float-right" placeholder="Search...">
        </div>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12 overflow-x-auto">
        <table class="table table2 table-hover" id="tableData">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N</th>
              <th class="wd-80p">Table</th>
              <th class="wd-15p text-right table-end-item">Action</th>
            </tr>
          </thead>
          <tbody v-for="(table, index) in dataSets" :key="index">
            <tr v-if="!removeList.includes(table.id)">
              <th scope="row" class="table-start-group">{{ ++index }}</th>
              <td>{{ table.table }}</td>
              <td class="text-right table-end-item">
                <a
                  href="javascript:;"
                  class="mr-2"
                  @click="edit('newTable', table.id)"
                >
                  <i class="fa fa-edit"></i>
                </a>
                <a href="javascript:;" @click="drop(table.id)">
                  <i class="fa fa-trash"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <Create />
        <Pagination />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Create from "./create";
import Pagination from "../../../components/pagination/pagination";
import {Search} from "../../../../../mixins/search";

export default {
  mixins: [Search],
  components: {
    Create, Pagination
  },
  methods: {
    edit(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "edit" });
    },
    drop(id) {
      this.$store.commit("setDataId", id);
      this.$store.commit("dropRecord", `api/table/destroy/${id}`);
    },getData(){
      this.$store.commit("getData", `api/table/size/${this.filter.size}`);
    }
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "removeList",
      "pagination",
      "eventMessage",
    ]),
  },
  mounted() {
    this.getData();
  },
  watch: {
    eventMessage(value) {
      if (value.indexOf("deleted success") >= 0) {
        this.$store.commit("setRemoveList", this.dataId);
      }
    },
  },
  destroyed() {
    this.$store.dispatch("destroyEvent");
  },
};
</script>